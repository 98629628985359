.BillContent{
    width: 100%;
    height: auto;
    padding-left: 5px;
    padding-right: 6px;
    z-index: 9999999;
}

.BillContent .imgQR {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.BillContent .imgQR .logo {
    margin-top: 1%;
    position: relative;
    width: 130px;
    height: 130px;
}

.BillContent .BillHeader {
  margin-top: 1%;
}

.BillContent .BillHeader .BillTitle{
    font-size: 40px;
    /* font-weight: bold; */
    text-align: center;
}

.BillContent .BillHeader .BillNO{
    font-size: 30px;
}

.BillContent .BillHeader .Queue{
    font-size: 30px;
}


.BillContent .BillHeader .BillTitle2{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}


.BillContent .BillHeader .Phone{
    font-size: 30px;
    text-align: left;
}

.BillContent .BillHeader .TableNo{
    font-size: 30px;
    text-align: left;
}


.BillContent .BillHeader .Cashier{
    font-size: 30px;
    text-align: left;
    margin-top: 5px;
}

.BillContent .BillHeader .ShowDate{
    font-size: 30px;
    text-align: left;
}

.BillContent .BillHeader .LocationName{
    font-size: 40px;
    text-align: left;
}

.BillContent .BillHeader .HeadLine{
    font-size: 40px;
    text-align: center;
}

.BillContent .BodyHeader {
    font-size: 30px;
    margin-top: 1%;
    display: flex;
    justify-content: space-between;
    text-align: right;
}


.BillContent .BillBody .BodyDetails .Items {
   font-size:  30px;
}

.BillContent .BillBody .BodyDetails .Items .Detail {
    text-align: left;
}

.BillContent .BillBody .BodyDetails .Items .Detail .mds {
    display: flex;
    justify-content: space-between;
}

.BillContent .BillBody .BodyDetails .Items .Name .ProductName {
    font-size: 30px;
    font-weight: bold;
}

.BillContent .BillFooter {
    margin-top: 10px;
}


.BillContent .BillFooter .subtotal {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillFooter .discout {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillFooter .tax {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillFooter .nettotal {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillFooter .paymentType{
    font-size: 30px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}


.BillContent .BillFooter .PaidMoreAmount {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillFooter .paidAmount {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillFooter .changeAmount {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}


.footer-line{
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-top: 4px dashed #000; border-right: none; border-bottom: none; border-left: none;
  }

.BillContent .BillFooter .TaxBeforeVat {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}
.BillContent .BillFooter .VAT {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}
.BillContent .BillFooter .TotalTax {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillFooter .Thankyou {
    font-size: 30px;
    text-align: center;
}


